<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 14.03.2021 11:41:49 UTC
          </div>
          <div id='tip'>
            For ROOM call use DOT allocation.

            So now it retraced back to where we started, you can top up now to the point it becomes from ordinary call+ to standard dot allocation. STAKE it. If you won't stake, don't buy at all. Note down money you invested in it. We will note it after this whole staking thingy is done and I announce to sell it. Cheers!
            (20 days ago) 13.03.2021 13:36:08 UTC
            Great opportunity, buy ROOM now,

            stake it until 3x from here.

            https://app.uniswap.org/#/swap?outputCurrency=0xad4f86a25bbc20ffb751f2fac312a0b4d8f88c64

            buy room ($3.5 now)

            2) stake room and eth on uniswap

            3) stake ROOM-ETH LP here:


                https://app.optionroom.finance/court-farming/court-roomethlp

            Write down how much money you spend now on all this and how much you'll have in room and court by the time you finish this process (3x in room reached and staked rewards paid out).
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ROOM',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
